<template>
  <b-container class="overflow-auto" fluid>
    <!-- Ad Interface controls -->
    <h5 class="my-3"><strong>VIDEO ADS</strong></h5>
    <a-row class="my-2" :gutter="[8]">
      <a-col :xl="3" :md="6" :xs="12">
        <a-checkbox v-model="showActive">
          Show only active
        </a-checkbox>
      </a-col>
      <a-col :xl="4" :md="12" :xs="24">
        <a-select allowClear placeholder="Campaign" style="width: 100%" :showSearch="true" :filterOption="true"
          optionFilterProp="children" v-model="selectedCampaign" @change="getFilteredAds">
          <a-select-option v-for="obj in ortbCampaign.list" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name }}</a-select-option>
        </a-select>
      </a-col>
      <a-col :xl="4" :md="12" :xs="24">
        <a-select allowClear placeholder="Account Manager" style="width: 100%" :showSearch="true" :filterOption="true"
          optionFilterProp="children" v-model="selectedUser" @change="getFilteredAds">
          <a-select-option v-for="obj in user.list" :key="obj.Id">[{{ obj.Id }}] {{ obj.FirstName }} {{ obj.LastName
          }}</a-select-option>
        </a-select>
      </a-col>
    </a-row>
    <b-row>
      <b-col class="my-1" md="6">
        <b-form-group class="mb-0" label="Per page:" label-cols-sm="2">
          <perPageSelect v-model="perPage" :storageKey="'ortb-video-ads'" :pageOptions="pageOptions" />
          <!-- <b-form-select v-model="perPage" :options="pageOptions" class="form-control ml-0 w-25"></b-form-select> -->
          <columnSelect :storageKey="'display-videoads-columns'" :options="fields"></columnSelect>
          <CreateButton module="display" target="videoad" />
        </b-form-group>
      </b-col>
      <b-col class="my-1 d-flex flex-row-reverse" md="6">
        <b-form-group class="mb-0">
          <b-input-group>
            <b-form-input v-model="filter" class="form-control w-50" placeholder="Type to Search"></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <!-- Main table element -->
    <b-table :sort-compare="sortCompare" :bordered="true" :current-page="currentPage" :fields="filteredFields" :filter="filter" :hover="true"
      :items="showVideoAds" :per-page="perPage" :small="true" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection" :striped="true" :tbody-tr-class="rowClass" show-empty stacked="md"
      @filtered="onFiltered">
      <template #cell(Name)="data">
        <!-- SM screen -->
        <div class="d-block d-sm-none">
          <!-- name slot -->
          <div>
            <div class="text-md-left text-center text-wrap text-break">{{ data.item.Name }}</div>
          </div>
        </div>
        <!-- * EXCEPT SM screen -->
        <div class="d-none d-sm-block">
          <!-- Name slot -->
          <div class="">
            <div data-toggle="tooltip" data-placement="top" :title="data.item.Name"
              class="text-md-left text-center text-break text-wrap">{{ data.item.Name }}</div>
          </div>
        </div>
      </template>
      <template #cell(CampaignName)="data">
        <!-- SM screen -->
        <div class="d-block d-sm-none">
          <!-- name slot -->
          <div>
            <div class="text-wrap text-md-left text-center text-break">{{ data.item.CampaignName }}</div>
          </div>
        </div>
        <!-- * EXCEPT SM screen -->
        <div class="d-none d-sm-block">
          <!-- CampaignName slot -->
          <div>
            <div data-toggle="tooltip" data-placement="top" :title="data.item.CampaignName"
              class="text-wrap text-md-left text-center text-break">{{ data.item.CampaignName }}</div>
          </div>
        </div>
      </template>
      <template #cell(Pubfeeds)="data">
        <div class="col col-md-12 d-flex justify-content-center justify-content-md-start">{{ data.item.Pubfeeds }}</div>
      </template>
      <template #cell(Rtbzones)="data">
        <div v-if="data.item.Rtbzones.length > 0">
          <a-tooltip slot="addonBefore" trigger="click" :overlayStyle="{ 'max-width': '200%' }">
            <template slot="title">
              <div className="zone-list">
                <div :key="zone.Id" v-for="zone in rtbzones">
                  {{
                    data.item.Rtbzones.includes(zone.Id) ? `[${zone.Id}] ${zone.Name}` : ''
                  }}
                </div>
              </div>
            </template>
            <a-button type="primary">
              {{ data.item.Rtbzones.length }}
            </a-button>
          </a-tooltip>
        </div>
      </template>
      <template #cell(Videozones)="data">
        <div v-if="data.item.Videozones.length > 0">
          <a-tooltip slot="addonBefore" trigger="click" :overlayStyle="{ 'max-width': '200%' }">
            <template slot="title">
              <div className="zone-list">
                <div :key="zone.Id" v-for="zone in videozones">
                  {{
                    data.item.Videozones.includes(zone.Id) ? `[${zone.Id}] ${zone.Name}` : ''
                  }}
                </div>
              </div>
            </template>
            <a-button type="primary">
              {{ data.item.Videozones.length }}
            </a-button>
          </a-tooltip>
        </div>
      </template>
      <template #cell(StatusString)="data">
        <a-checkbox :checked="(data.item.Status == 0)" @change="toggleVideoAdStatus(data.item)">
        </a-checkbox>
      </template>
      <template #cell(Placement)="data">
        {{ formatPlacement(data.item.Placement) }}
      </template>
      <template #cell(Delivery)="data">
        {{ formatDelivery(data.item.Delivery) }}
      </template>
      <template #cell(Interstitial)="data">
        {{ formatInterstitial(data.item.Interstitial) }}
      </template>
      <template #cell(actions)="data">
        <router-link class="add-button" @click.stop
          :to="{ name: 'display-videoad-update', params: { id: data.item.Id }, }">
          <b-button class="btn-info mr-1" size="sm" @click="editVideoAd(data.item.Id)"><i
              class="fa fa-edit"></i></b-button>
        </router-link>
        <b-button class="btn-info mr-1" size="sm" @click="copyVideoAd(data.item.Id, data.item)"><i
            class="fa fa-copy"></i></b-button>
      </template>
    </b-table>

    <b-row>
      <b-col class="my-1" md="6">
        <p>Showing {{ ((currentPage - 1) * perPage).toLocaleString() }} to {{
          (((currentPage - 1) * perPage) + perPage > totalRows ? totalRows : ((currentPage - 1) * perPage) +
            perPage).toLocaleString()
        }} of {{ parseInt(totalRows).toLocaleString() }} entries</p>
      </b-col>
      <b-col class="my-1 d-flex flex-row-reverse" md="6">
        <b-pagination v-model="currentPage" :per-page="perPage" :total-rows="totalRows" class="my-0"></b-pagination>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import columnSelect from '@/components/custom/tableColumnSelect'
import { Modal } from 'ant-design-vue'
import { cloneVideoAd } from '@/api/display/videoad'
import perPageSelect from '@/components/custom/PerPageSelect'
import { getRTBZonesShort } from '@/api/display/rtbzone'
import { getVideozonesShort } from '@/api/display/videozone'

export default {
  components: {
    columnSelect,
    perPageSelect,
    CreateButton: () => import('@/components/custom/create-button/index.vue'),
  },
  data() {
    return {
      selectedCampaign: [],
      selectedUser: [],
      days: [
        {
          title: 'Saturday',
          shortcut: 'sat',
        },
        {
          title: 'Sunday',
          shortcut: 'sun',
        },
        {
          title: 'Monday',
          shortcut: 'mon',
        },
      ],
      selectedDays: ['sun'],
      // set field and turn received values from numbers to phrases in the ui templates
      fields: [
        {
          key: 'actions',
          label: 'Actions',
          show: true,
          class: 'text-center',
          thStyle: 'width:90px',
        },
        {
          key: 'Id',
          label: 'Ad ID',
          show: true,
          headerTitle: 'Ad ID',
          sortable: true,
          thStyle: 'width:70px',
          tdClass: 'text-right',
        },
        {
          key: 'Name',
          label: 'Ad Name',
          show: true,
          headerTitle: 'Ad Name',
          sortable: true,
        },
        {
          key: 'CampaignName',
          label: 'Campaign',
          show: true,
          headerTitle: 'Campaign Name',
          sortable: true,
          thStyle: 'width:180px',
        },
        {
          key: 'DefaultCPM',
          label: 'Default CPM',
          headerTitle: 'Default Cost per Mile',
          show: true,
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-md-right text-center',
          formatter: '$formatCurrency',
          thStyle: 'width:110px',
        },
        {
          key: 'BidsDailyLimit',
          label: 'Bids Daily Limit',
          headerTitle: 'Bids Daily Limit',
          show: true,
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
          formatter: '$formatNumber',
          thStyle: 'width:130px',
          summed: false,
        },
        {
          key: 'ImpressionsDailyLimit',
          label: 'Imp. Daily Limit',
          show: true,
          headerTitle: 'Impressions Daily Limit',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
          formatter: '$formatNumber',
          thStyle: 'width:130px',
          summed: false,
        },
        {
          key: 'ClicksDailyLimit',
          label: 'Clicks Daily Limit',
          show: true,
          headerTitle: 'Clicks Daily Limit',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
          formatter: '$formatNumber',
          thStyle: 'width:140px',
          summed: false,
        },
        {
          key: 'SpentDailyLimit',
          label: 'Spent Daily Limit',
          show: true,
          headerTitle: 'Spent Daily Limit',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
          formatter: '$formatNumber',
          thStyle: 'width:140px',
          summed: false,
        },
        {
          key: 'Title',
          label: 'Title',
          show: false,
          tdClass: 'text-break',
          sortable: true,
        },
        {
          key: 'Description',
          label: 'Description',
          show: false,
          tdClass: 'text-break',
          sortable: true,
        },
        {
          key: 'AdDomainBrand',
          label: 'Ad Domain/Brand',
          show: false,
          sortable: true,
        },
        {
          key: 'AdAppBundle',
          label: 'Ad App Bundle',
          show: false,
          sortable: true,
        },
        {
          key: 'DestinationUrl',
          label: 'Destination URL',
          show: false,
          sortable: true,
        },
        {
          key: 'Skipoffset',
          label: 'Skip Offset',
          show: false,
          sortable: true,
          class: 'text-center',
          thStyle: 'width:105px',
        },
        // {
        //   key: 'AdPosition',
        //   label: 'Ad Position',
        //   show: false,
        //   sortable: false,
        // },
        {
          key: 'Placement',
          label: 'Placement',
          show: false,
          sortable: true,
          tdClass: 'text-md-left text-center',
          thStyle: 'width:180px',
        },
        {
          key: 'Delivery',
          label: 'Delivery',
          show: false,
          sortable: true,
          tdClass: 'text-md-left text-center',
          thStyle: 'width:100px',
        },
        // {
        //   key: 'PlaybackStart',
        //   label: 'Playback Start',
        //   show: false,
        //   sortable: false,
        //   tdClass: 'text-md-left text-center',
        // },
        // {
        //   key: 'PlaybackEnd',
        //   label: 'Playback End',
        //   show: false,
        //   sortable: false,
        //   tdClass: 'text-md-left text-center',
        // },
        {
          key: 'Interstitial',
          label: 'Interstitial',
          show: false,
          sortable: true,
          tdClass: 'text-md-left text-center',
          thStyle: 'width:100px',
        },
        {
          key: 'AccManagerId',
          label: 'Acc. Mngr. ID',
          show: false,
          headerTitle: 'Account Manager ID',
          sortable: true,
          thStyle: 'width:110px',
          tdClass: 'text-right',
        },
        {
          key: 'AccManagerName',
          label: 'Account Manager',
          show: true,
          headerTitle: 'Account Manager Name',
          sortable: true,
        },
        {
          key: 'StatusString',
          label: 'Active',
          show: true,
          class: 'text-center',
          thStyle: 'width:80px',
        },
      ],
      rtbzones: null,
      videozones: null,
      currentPage: 1,
      perPage: 50,
      pageOptions: [20, 50, 100, 200],
      sortBy: null,
      sortDesc: false,
      sortDirection: 'desc',
      filter: null,
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
    }
  },
  computed: {
    ...mapState(['videoad', 'ortbCampaign', 'settings', 'user']),
    ...mapGetters('videoad', ['getVideoAds', 'getActiveVideoAds']),
    showActive: {
      get: function () {
        return this.settings.showActiveVideoAds
      },
      set: function (value) {
        this.$store.commit('CHANGE_SETTING', { setting: 'showActiveVideoAds', value })
      },
    },
    showVideoAds() {
      return this.showActive ? this.getActiveVideoAds : this.getVideoAds
    },
    filteredFields() {
      return this.fields.filter(c => c.show)
    },
    totalRows() {
      return this.showVideoAds.length
    },
  },
  methods: {
    sortCompare(a, b, key) {
      if (key === 'Rtbzones' || key === 'Videozones') {
        const lenA = Array.isArray(a[key]) ? a[key].length : 0
        const lenB = Array.isArray(b[key]) ? b[key].length : 0
        return lenA - lenB
      }
      return a[key] > b[key] ? 1 : (a[key] < b[key] ? -1 : 0)
    },
    formatInterstitial(value) {
      if (value === 0) {
        return 'No'
      } else if (value === 1) {
        return 'Yes'
      } else if (value === 2) {
        return 'All'
      }
    },
    formatDelivery(value) {
      if (value === 1) {
        return 'Streaming'
      } else if (value === 2) {
        return 'Progressive'
      } else if (value === 3) {
        return 'Download'
      }
    },
    formatPlacement(value) {
      if (value === 1) {
        return 'In-Stream'
      } else if (value === 2) {
        return 'In-Banner'
      } else if (value === 3) {
        return 'In-Article'
      } else if (value === 4) {
        return 'In-Feed'
      } else if (value === 5) {
        return 'Interstitial/Slider/Floating'
      }
    },
    actionGetVideoAds() {
      this.$store.dispatch('videoad/LOAD_ADS_FOR_CAMPAIGN', { CampaignId: this.selectedCampaign })
    },
    getFilteredAds() {
      if (this.selectedCampaign !== []) {
        window.localStorage.setItem('video-ad-campaign-key', JSON.stringify(this.selectedCampaign))
      }
      if (this.selectedUser !== []) {
        window.localStorage.setItem('video-ad-user-key', JSON.stringify(this.selectedUser))
      }
      this.$store.dispatch('videoad/LOAD_ADS_FOR_USER_CAMP', { AccManagerId: this.selectedUser, CampaignId: this.selectedCampaign })
    },
    getVideoAd(id) {
      cloneVideoAd(id).then(response => {
        if (response) {
          // this.$router.push('/display/videoad/update/' + response.Videoad.Id)
          this.$router.push({ name: 'display-videoad-update', params: { id: response.Videoad.Id } }).catch(() => { })
          this.$notification.success({
            message: 'Video Ad cloned',
            description: `Video Ad: ${response.Videoad.Name} has been successfully cloned.`,
          })
        }
      }).catch(error => console.log(error))
    },
    copyVideoAd(id, ad) {
      Modal.confirm({
        title: 'Are you sure you want to clone Video Ad?',
        content: h => <div>{status} Clone Video Ad: <strong>{ad.Name}</strong>?</div>,
        onOk: function () {
          this.getVideoAd(id)
        }.bind(this),
      })
    },
    rowClass(item, type) {
      if (item != null && item.Status === 1) return 'text-muted'
    },
    editVideoAd(id) {
      if (this.selectedCampaign && this.selectedCampaign !== []) {
        window.localStorage.setItem('video-ad-campaign-key', JSON.stringify(this.selectedCampaign))
        if (window.localStorage.getItem('video-ad-user-key')) {
          window.localStorage.setItem('video-ad-user-key', JSON.stringify(this.selectedUser))
        }
      }
      // this.$router.push('/display/videoad/update/' + id)
      this.$router.push({ name: 'display-videoad-update', params: { id: id } }).catch(() => { })
    },
    toggleVideoAdStatus(videoad) {
      const status = videoad.Status === 0 ? 'Deactivate' : 'Activate'
      Modal.confirm({
        title: 'Are you sure you want to change Video Ad status?',
        content: h => <div>{status} Video Ad <strong>{videoad.Name}</strong>?</div>,
        onOk: function () {
          const newStatus = videoad.Status === 0 ? 1 : 0
          this.$store.dispatch('videoad/CHANGE_STATUS', {
            id: videoad.Id,
            status: newStatus,
          })
        }.bind(this),
      })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
  created() {
    if ((window.localStorage.getItem('video-ad-campaign-key')) !== null || window.localStorage.getItem('video-ad-user-key') !== null) {
      this.$store.dispatch('videoad/LOAD_ADS_FOR_USER_CAMP', { CampaignId: parseInt(window.localStorage.getItem('video-ad-campaign-key')) || [], AccManagerId: parseInt(window.localStorage.getItem('video-ad-user-key')) || [] })
    } else {
      this.$store.dispatch('videoad/LOAD_ADS')
    }
    this.$store.dispatch('ortbCampaign/LOAD_CAMPAIGNS_SHORT')
    this.$store.dispatch('user/LOAD_USERS')
      .then(() => {
        if (window.localStorage.getItem('video-ad-campaign-key')) {
          const filterCampaign = parseInt(window.localStorage.getItem('video-ad-campaign-key'))
          if (filterCampaign !== [] && !isNaN(filterCampaign)) {
            this.selectedCampaign = filterCampaign
          }
          window.localStorage.removeItem('video-ad-campaign-key')
        }
        if (window.localStorage.getItem('video-ad-user-key')) {
          const filterUser = parseInt(window.localStorage.getItem('video-ad-user-key'))
          if (filterUser !== [] && filterUser) {
            this.selectedUser = filterUser
          }
          window.localStorage.removeItem('video-ad-user-key')
        }
      })
    getRTBZonesShort()
      .then(res => {
        this.rtbzones = res
      })
    getVideozonesShort()
      .then(res => {
        this.videozones = res
      })
  },
  mounted() {
  },
}
</script>
